<template>
	<UiButton
		class="offer-item-btn"
		:name="'Скрыть'"
		:border="'light'"
		@click="closeEvent" />
</template>

<script setup lang="ts">
	import { api } from "~/api/api";
	import type { IApiResponsePayload } from "@/assets/ts/entities/api/interfaces/IApiResponsePayload";
	import type { IApiResponseMessage } from "@/assets/ts/entities/api/interfaces/IApiResponseMessage";
	import type { TFetchError } from "@/assets/ts/entities/api/types/TFetchError";

	const props = defineProps<{ eventId: string }>();

	const {
		messagesStore: { fetchMessages },
		modalErrorStore: { open: showError },
	} = useStores();

	const {
		data: closeData,
		error: closeError,
		refresh: closeRefresh,
	} = await $useFetch<IApiResponsePayload<IApiResponseMessage>, TFetchError>(() => api.closeEvent(props.eventId), {
		method: "POST",
		lazy: true,
		immediate: false,
		watch: false,
	});

	async function closeEvent() {
		await closeRefresh();

		const error = closeError.value;
		const data = closeData.value;

		if (error !== null) {
			showError(error.data?.message || "", true);
			return;
		}

		if (data !== null) {
			const result = data.data.result;
			const message = data.data.message;

			if (result) {
				await fetchMessages();
			} else {
				showError(message, true);
			}
		}
	}
</script>
