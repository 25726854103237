<template>
	<div class="label-date">
		{{ compareDateWithToday }}
	</div>
</template>

<script setup lang="ts">
	const props = defineProps<{
		date: string;
	}>();

	const compareDateWithToday: ComputedRef<string> = computed((): string => {
		const today: Date = new Date();
		const formattedToday: string = today.toLocaleDateString("ru-RU");

		return formattedToday === props.date ? "Сегодня" : props.date;
	});
</script>

<style lang="scss" scoped>
	@import "label-date";
</style>
