<template>
	<div class="message-file">
		<a
			class="link message-file__link"
			:href="`${REST_API_BROWSER_BASE_URL}${message.file.download}`"
			:title="`Скачать ${message.file.original}`">
			<NuxtIcon
				class="message-file__download"
				name="download-file" />

			<span class="message-file__name">{{ message.file.original }}</span>
		</a>

		<PageChatChatMessageMeta :message="message" />
	</div>
</template>

<script setup lang="ts">
	import type { IMessageFile } from "@/assets/ts/global/interfaces/IMessageFile";

	defineProps<{ message: IMessageFile }>();

	const {
		public: { REST_API_BROWSER_BASE_URL },
	} = useRuntimeConfig();
</script>

<style scoped lang="scss">
	@import "message-file";
</style>
