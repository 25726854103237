<template>
	<div class="order">
		<PageChatOrderInfo :order="order" />

		<div
			v-if="order.event_id && !getEventsId.includes(order.event_id)"
			class="offer-item__buttons">
			<PageChatAcceptEvent
				v-if="order.status === 'new'"
				:event-id="order.event_id" />

			<PageChatCloseEvent
				v-if="order.status !== 'accept' && !order.is_org_trigger"
				:event-id="order.event_id" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import type { IMessage } from "@/assets/ts/global/interfaces/IMessage";

	defineProps<{
		order: IMessage;
	}>();

	const { eventsStore } = useStores();
	const { getEventsId } = storeToRefs(eventsStore);
</script>

<style lang="scss">
	@import "order";
</style>
