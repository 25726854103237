<template>
	<div>
		<UiButton
			:class="['offer-item-btn', fetchPending ? 'button--disable' : '']"
			name="Запустить"
			:border="'light'"
			:disabled="fetchPending"
			@click="startEvent" />
	</div>
</template>

<script setup lang="ts">
	import { storeToRefs } from "pinia";
	import { api } from "~/api/api";
	import { $useFetch } from "~/composables/useFetch";
	import type { IApiResponsePayload } from "@/assets/ts/entities/api/interfaces/IApiResponsePayload";
	import type { IApiResponseEvent } from "@/assets/ts/entities/api/interfaces/IApiResponseEvent";
	import type { TFetchError } from "@/assets/ts/entities/api/types/TFetchError";
	import type { ComputedRef } from "vue";
	import { hasAnyEventAttribute } from "~/utils/has-any-event-attribute";

	const props = defineProps<{
		eventId: string;
	}>();

	const {
		eventsStore,
		modalErrorStore: { open: showError },
		activeEventStore: { setActiveEvent },
	} = useStores();

	const { getNumberEvents, getEventById, getEventIndex } = storeToRefs(eventsStore);

	const {
		data: startEventData,
		error: startEventError,
		refresh: startEventRefresh,
		status: startEventStatus,
	} = await $useFetch<IApiResponsePayload<IApiResponseEvent>, TFetchError>(() => api.startEvent(props.eventId), {
		method: "POST",
		lazy: true,
		immediate: false,
		watch: false,
	});

	const fetchPending: ComputedRef<boolean> = computed((): boolean => startEventStatus.value === "pending");

	async function startEvent() {
		if (getNumberEvents.value >= 3) {
			showError("У вас уже запущено 3 активных события, чтобы инициировать новое событие завершите одно из текущих событий");
		} else {
			await startEventRefresh();

			if (startEventError.value !== null) {
				showError(startEventError.value.data?.message || "", true);
			} else if (startEventData.value !== null) {
				if (startEventData.value.data?.result) {
					await fetchMultiData();

					const eventNumber = getEventIndex.value(props.eventId);

					if (eventNumber !== -1) {
						const acceptedEvent = getEventById.value(props.eventId);

						if (acceptedEvent !== undefined) {
							setActiveEvent({
								...acceptedEvent,
								number: eventNumber + 1,
								hasAnyAttribute: hasAnyEventAttribute(acceptedEvent),
							});
						}
					}
				} else {
					showError(startEventData.value.data?.message || "", true);
				}
			}
		}
	}
</script>
