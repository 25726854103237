<template>
	<div
		ref="dialogHistory"
		class="dialog-history">
		<div
			ref="dialogHistory"
			class="dialog-history__container">
			<template
				v-for="(message, index) in messages"
				:key="message.id">
				<PageChatLabelDate
					v-if="isNewDayMessage(index, messages)"
					:date="useDateFormat(message.created)" />

				<PageChatChatMessage :message="message" />
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { isNewDayMessage } from "@/utils/is-new-day-message";
	import { useDateFormat } from "@/composables/useDateFormat";
	import type { IMessage } from "@/assets/ts/global/interfaces/IMessage";

	const props = defineProps<{ messages: IMessage[] }>();

	const dialogHistory = ref();

	watch(
		() => props.messages,
		() => {
			setTimeout(() => {
				if (!dialogHistory.value) return;

				dialogHistory.value.scrollTo({ top: dialogHistory.value.scrollHeight });
			}, 100);
		},
	);
</script>

<style scoped lang="scss">
	@import "dialog-history";
</style>
